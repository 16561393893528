/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TLawyerToggle
 */
export interface TLawyerToggle {
    /**
     * 
     * @type {string}
     * @memberof TLawyerToggle
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof TLawyerToggle
     */
    active: boolean;
}

export function TLawyerToggleFromJSON(json: any): TLawyerToggle {
    return TLawyerToggleFromJSONTyped(json, false);
}

export function TLawyerToggleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TLawyerToggle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
    };
}

export function TLawyerToggleToJSON(value?: TLawyerToggle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'active': value.active,
    };
}


