/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ESparebank1Contract {
    Samboerkontrakt = 'samboerkontrakt',
    Laneavtale = 'laneavtale',
    Fremtidsfullmakt = 'fremtidsfullmakt',
    Gjeldsbrev = 'gjeldsbrev',
    Leiekontrakt = 'leiekontrakt',
    Testament = 'testament',
    Handverkertjenester = 'handverkertjenester'
}

export function ESparebank1ContractFromJSON(json: any): ESparebank1Contract {
    return ESparebank1ContractFromJSONTyped(json, false);
}

export function ESparebank1ContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESparebank1Contract {
    return json as ESparebank1Contract;
}

export function ESparebank1ContractToJSON(value?: ESparebank1Contract | null): any {
    return value as any;
}

