/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InlineObject3,
  InlineObject3FromJSON,
  InlineObject3ToJSON,
  PaginateResultUser,
  PaginateResultUserFromJSON,
  PaginateResultUserToJSON,
  TCheckLawyerSchedule,
  TCheckLawyerScheduleFromJSON,
  TCheckLawyerScheduleToJSON,
  TLawyerSchedule,
  TLawyerScheduleFromJSON,
  TLawyerScheduleToJSON,
  TLawyerToggle,
  TLawyerToggleFromJSON,
  TLawyerToggleToJSON,
  User,
  UserFromJSON,
  UserToJSON,
} from "../models";

export interface GetAllLawyersRequest {
  area: string;
}

export interface GetLegalAreasRequest {
  id: string;
}

export interface GetScheduleForUserRequest {
  tCheckLawyerSchedule: TCheckLawyerSchedule;
}

export interface GetToggleRequest {
  tLawyerToggle: TLawyerToggle;
}

export interface UpdateLegalAreasRequest {
  inlineObject3: InlineObject3;
}

/**
 *
 */
export class LawyerServiceApi extends runtime.BaseAPI {
  /**
   */
  async getAllAdminRaw(): Promise<runtime.ApiResponse<PaginateResultUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/lawyers/all/admin`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginateResultUserFromJSON(jsonValue)
    );
  }

  /**
   */
  async getAllAdmin(): Promise<PaginateResultUser> {
    const response = await this.getAllAdminRaw();
    return await response.value();
  }

  /**
   */
  async getAllLawyersRaw(
    requestParameters: GetAllLawyersRequest
  ): Promise<runtime.ApiResponse<Array<User>>> {
    if (
      requestParameters.area === null ||
      requestParameters.area === undefined
    ) {
      throw new runtime.RequiredError(
        "area",
        "Required parameter requestParameters.area was null or undefined when calling getAllLawyers."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.area !== undefined) {
      queryParameters["area"] = requestParameters.area;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/lawyers/all`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(UserFromJSON)
    );
  }

  /**
   */
  async getAllLawyers(
    requestParameters: GetAllLawyersRequest
  ): Promise<Array<User>> {
    const response = await this.getAllLawyersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getAvailabilityRaw(): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/lawyers/times`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async getAvailability(): Promise<any> {
    const response = await this.getAvailabilityRaw();
    return await response.value();
  }

  /**
   */
  async getLegalAreasRaw(
    requestParameters: GetLegalAreasRequest
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getLegalAreas."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/lawyers/areas`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async getLegalAreas(
    requestParameters: GetLegalAreasRequest
  ): Promise<Array<string>> {
    const response = await this.getLegalAreasRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getScheduleForUserRaw(
    requestParameters: GetScheduleForUserRequest
  ): Promise<runtime.ApiResponse<TLawyerSchedule>> {
    if (
      requestParameters.tCheckLawyerSchedule === null ||
      requestParameters.tCheckLawyerSchedule === undefined
    ) {
      throw new runtime.RequiredError(
        "tCheckLawyerSchedule",
        "Required parameter requestParameters.tCheckLawyerSchedule was null or undefined when calling getScheduleForUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/lawyers/schedules`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TCheckLawyerScheduleToJSON(requestParameters.tCheckLawyerSchedule),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TLawyerScheduleFromJSON(jsonValue)
    );
  }

  /**
   */
  async getScheduleForUser(
    requestParameters: GetScheduleForUserRequest
  ): Promise<TLawyerSchedule> {
    const response = await this.getScheduleForUserRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getToggleRaw(
    requestParameters: GetToggleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.tLawyerToggle === null ||
      requestParameters.tLawyerToggle === undefined
    ) {
      throw new runtime.RequiredError(
        "tLawyerToggle",
        "Required parameter requestParameters.tLawyerToggle was null or undefined when calling getToggle."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/lawyers/toggle`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: TLawyerToggleToJSON(requestParameters.tLawyerToggle),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async getToggle(requestParameters: GetToggleRequest): Promise<void> {
    await this.getToggleRaw(requestParameters);
  }

  /**
   */
  async updateLegalAreasRaw(
    requestParameters: UpdateLegalAreasRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.inlineObject3 === null ||
      requestParameters.inlineObject3 === undefined
    ) {
      throw new runtime.RequiredError(
        "inlineObject3",
        "Required parameter requestParameters.inlineObject3 was null or undefined when calling updateLegalAreas."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/lawyers/areas`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: InlineObject3ToJSON(requestParameters.inlineObject3),
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async updateLegalAreas(
    requestParameters: UpdateLegalAreasRequest
  ): Promise<any> {
    const response = await this.updateLegalAreasRaw(requestParameters);
    return await response.value();
  }
}
