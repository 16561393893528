import "../styles/globals.css";
import "../styles/calendar.css";
import "../styles/vars.css";
import { UserContext } from "src/components/context/UserContext";
import { useEffect, useMemo, useState } from "react";
import Head from "next/head";
import { AlertContext } from "src/components/context/AlertContext";
import Router, { useRouter } from "next/router";
import Alert from "src/components/static/Alert";
import { MediaContextProvider } from "src/components/Media";
import NProgress from "nprogress";
import { DefaultSeo } from "next-seo";
import * as gtag from "src/lib/gtag";
import CookieAlert from "src/components/static/CookieAlert";
import { DiscountContext } from "src/components/context/DiscountContext";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useIdleTimer } from "react-idle-timer";
import auth from "../api/authentication";
import { appWithTranslation } from "next-i18next";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import FilterContext from "../components/context/FilterContext";
import { CaseServiceApi, Configuration, DiscountServiceApi } from "../api/gen";
import config from "../config/api-config";
import jwt_decode from "jwt-decode";

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();

  window.scrollTo(0, 0);
});
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }) {
  const [user, setUser] = useState(null);
  const router = useRouter();
  const [alert, setAlert] = useState(null);
  const [discount, setDiscount] = useState(null);

  const [filters, setFilters] = useState({
    search: "",
    status: "active",
    free: "",
    user: router.query.user,
  });

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const handleOnIdle = () => {
    if (user) {
      auth.logout();
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const filterValue = useMemo(() => ({ filters, setFilters }), [
    filters,
    setFilters,
  ]);

  const alertValue = useMemo(() => ({ alert, setAlert }), [alert, setAlert]);

  const discountValue = useMemo(() => ({ discount, setDiscount }), [
    discount,
    setDiscount,
  ]);
  // @ts-ignore
  useEffect(async () => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
    if (localStorage.getItem("discount")) {
      setDiscount(JSON.parse(localStorage.getItem("discount")));
    }
    if (localStorage.getItem("intent") && localStorage.getItem("user")) {
      // window.alert("Please wait, submitting...");
      const intent = JSON.parse(localStorage.getItem("intent"));
      if (intent) {
        new CaseServiceApi(new Configuration(config))
          .createCase({
            tCreateCase: {
              title: "Test",
              wizardData: intent,
            },
          })
          .then((result) => {
            localStorage.removeItem("intent");
            router.push("/sak/" + result.id);
          });
      }
    }
  }, []);

  const theme = extendTheme({
    colors: {
      legalis: {
        50: "#44337A",
        100: "#B794F4",
        500: "#637a9e", // you need this
      },
    },
    components: {
      Steps,
    },
  });

  // @ts-ignore
  useEffect(async () => {
    if (router && router.query) {
      if (router.query.medlem) {
        if (router.query.medlem !== "null") {
          const fetchedDiscount = await new DiscountServiceApi(
            new Configuration(config)
          ).getDiscount({ slug: router.query.medlem as string });
          if (fetchedDiscount) {
            console.log(fetchedDiscount);
            setDiscount(fetchedDiscount);
            localStorage.setItem("discount", JSON.stringify(fetchedDiscount));
          }
        } else {
          setDiscount(null);
          localStorage.removeItem("discount");
        }
      }
    }
  }, [router]);

  useEffect(() => {
    if(localStorage.getItem("token")) {
      const decoded_token = jwt_decode(localStorage.getItem("token"));
      console.log(decoded_token)
      if (discount && (decoded_token as any).exp > Date.now() / 1000) {
        console.log(discount);
        new DiscountServiceApi(new Configuration(config)).setDiscountForUser({
          tSetDiscount: {
            id: discount.id,
          },
        });
      }
    }
  }, [discount]);

  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://use.typekit.net/uew0mjd.css" />
        <link rel="stylesheet" type="text/css" href="/nprogress.css" />
        <meta
          name="google-site-verification"
          content="cdmgLHdZkcN-uMNf5_904hYuzYISds7O5z1llvaCaEg"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/seo-icons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/seo-icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/seo-icons/favicon-16x16.png"
        />
        <link rel="manifest" href="/images/seo-icons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/images/seo-icons/safari-pinned-tab.svg"
          color="#637a9e"
        />
        <meta name="msapplication-TileColor" content="#637a9e" />
        <meta name="theme-color" content="#637a9e" />
      </Head>
      <DefaultSeo
        description={`Advokattjenester trenger ikke å ha høy terskel. Våre advokater kan du når som helst bestille og møte der du er på nett, eller fysisk i Oslo. Levert av ${process.env.NEXT_PUBLIC_FULL_COMPANY_NAME}.`}
        openGraph={{
          type: "website",
          locale: "nb_NO",
          url: process.env.NEXT_PUBLIC_FRONTEND_URL,
          images: [
            {
              url: "https://sak.legalis.no/SEO-cover-v2.jpg",
              width: 1350,
              height: 1080,
              alt: "En advokat rett opp av lomma",
            },
          ],
        }}
        title={`Advokat rett i nettleseren - ${process.env.NEXT_PUBLIC_FULL_COMPANY_NAME}`}
      />
      <ChakraProvider theme={theme}>
        <FilterContext.Provider value={filterValue}>
          <MediaContextProvider>
            <DiscountContext.Provider value={discountValue}>
              <UserContext.Provider value={value}>
                <AlertContext.Provider value={alertValue}>
                  <Alert alert={alert} />
                  <Component {...pageProps} />
                  <CookieAlert />
                </AlertContext.Provider>
              </UserContext.Provider>
            </DiscountContext.Provider>
          </MediaContextProvider>
        </FilterContext.Provider>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
