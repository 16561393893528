/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TChangeLawyer
 */
export interface TChangeLawyer {
    /**
     * 
     * @type {string}
     * @memberof TChangeLawyer
     */
    lawyerId: string;
    /**
     * 
     * @type {string}
     * @memberof TChangeLawyer
     */
    caseId: string;
}

export function TChangeLawyerFromJSON(json: any): TChangeLawyer {
    return TChangeLawyerFromJSONTyped(json, false);
}

export function TChangeLawyerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TChangeLawyer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lawyerId': json['lawyerId'],
        'caseId': json['caseId'],
    };
}

export function TChangeLawyerToJSON(value?: TChangeLawyer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lawyerId': value.lawyerId,
        'caseId': value.caseId,
    };
}


