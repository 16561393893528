/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCreateWizard
 */
export interface TCreateWizard {
    /**
     * 
     * @type {any}
     * @memberof TCreateWizard
     */
    data: any | null;
    /**
     * 
     * @type {string}
     * @memberof TCreateWizard
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateWizard
     */
    title: string;
}

export function TCreateWizardFromJSON(json: any): TCreateWizard {
    return TCreateWizardFromJSONTyped(json, false);
}

export function TCreateWizardFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateWizard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'slug': json['slug'],
        'title': json['title'],
    };
}

export function TCreateWizardToJSON(value?: TCreateWizard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'slug': value.slug,
        'title': value.title,
    };
}


