/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCreateCase
 */
export interface TCreateCase {
    /**
     * 
     * @type {any}
     * @memberof TCreateCase
     */
    contractData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof TCreateCase
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateCase
     */
    referingBank?: string;
    /**
     * 
     * @type {any}
     * @memberof TCreateCase
     */
    wizardData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof TCreateCase
     */
    lawyer?: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateCase
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateCase
     */
    title: string;
}

export function TCreateCaseFromJSON(json: any): TCreateCase {
    return TCreateCaseFromJSONTyped(json, false);
}

export function TCreateCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractData': !exists(json, 'contractData') ? undefined : json['contractData'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'referingBank': !exists(json, 'referingBank') ? undefined : json['referingBank'],
        'wizardData': !exists(json, 'wizardData') ? undefined : json['wizardData'],
        'lawyer': !exists(json, 'lawyer') ? undefined : json['lawyer'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'title': json['title'],
    };
}

export function TCreateCaseToJSON(value?: TCreateCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractData': value.contractData,
        'uid': value.uid,
        'referingBank': value.referingBank,
        'wizardData': value.wizardData,
        'lawyer': value.lawyer,
        'user': value.user,
        'title': value.title,
    };
}


