import "isomorphic-unfetch";
import { ResponseContext } from "../api/gen";
import authentication from "../api/authentication";
import { download } from "../helpers/handle-file-response";
import { apiUrl } from "../api/config";
const isServer = () => typeof window === "undefined";

function returnToken() {
  if (typeof window === "undefined") return null;
  return localStorage.getItem("token");
}

const config = {
  basePath: apiUrl,
  headers: {
    Authorization: "Bearer " + returnToken(),
  },
  middleware: [
    {
      post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status === 403) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          authentication.login(urlParams.get("referal"));
        }
        if (context.response.url.includes("/file/download")) {
          return context.response.blob().then((blob) => {
            if (!context.response.ok) {
              return Promise.reject("COULD_NOT_DOWNLOAD");
            }
            const fileName = decodeURI(
              context.response.headers.get("content-disposition")
            );
            const file = window.URL.createObjectURL(blob);
            download(file, fileName);

            return;
          });
        }
        return;
      },
    },
  ],
};

export default config;
