/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCreateOffer
 */
export interface TCreateOffer {
    /**
     * 
     * @type {string}
     * @memberof TCreateOffer
     */
    packageName: string;
    /**
     * 
     * @type {number}
     * @memberof TCreateOffer
     */
    referingBank: number;
    /**
     * 
     * @type {boolean}
     * @memberof TCreateOffer
     */
    isPackage: boolean;
    /**
     * 
     * @type {number}
     * @memberof TCreateOffer
     */
    time?: number;
    /**
     * 
     * @type {string}
     * @memberof TCreateOffer
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof TCreateOffer
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof TCreateOffer
     */
    id: string;
}

export function TCreateOfferFromJSON(json: any): TCreateOffer {
    return TCreateOfferFromJSONTyped(json, false);
}

export function TCreateOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateOffer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packageName': json['packageName'],
        'referingBank': json['referingBank'],
        'isPackage': json['isPackage'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'description': json['description'],
        'price': json['price'],
        'id': json['id'],
    };
}

export function TCreateOfferToJSON(value?: TCreateOffer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packageName': value.packageName,
        'referingBank': value.referingBank,
        'isPackage': value.isPackage,
        'time': value.time,
        'description': value.description,
        'price': value.price,
        'id': value.id,
    };
}


