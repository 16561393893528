/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TLawyerSchedule
 */
export interface TLawyerSchedule {
    /**
     * 
     * @type {string}
     * @memberof TLawyerSchedule
     */
    scheduleId: string;
    /**
     * 
     * @type {Array<Date>}
     * @memberof TLawyerSchedule
     */
    slot: Array<Date>;
}

export function TLawyerScheduleFromJSON(json: any): TLawyerSchedule {
    return TLawyerScheduleFromJSONTyped(json, false);
}

export function TLawyerScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TLawyerSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'slot': json['slot'],
    };
}

export function TLawyerScheduleToJSON(value?: TLawyerSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduleId': value.scheduleId,
        'slot': value.slot,
    };
}


