/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCheckLawyerSchedule
 */
export interface TCheckLawyerSchedule {
    /**
     * 
     * @type {number}
     * @memberof TCheckLawyerSchedule
     */
    interval: number;
    /**
     * 
     * @type {string}
     * @memberof TCheckLawyerSchedule
     */
    dateTime: string;
    /**
     * 
     * @type {string}
     * @memberof TCheckLawyerSchedule
     */
    lawyerId: string;
}

export function TCheckLawyerScheduleFromJSON(json: any): TCheckLawyerSchedule {
    return TCheckLawyerScheduleFromJSONTyped(json, false);
}

export function TCheckLawyerScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCheckLawyerSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interval': json['interval'],
        'dateTime': json['dateTime'],
        'lawyerId': json['lawyerId'],
    };
}

export function TCheckLawyerScheduleToJSON(value?: TCheckLawyerSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interval': value.interval,
        'dateTime': value.dateTime,
        'lawyerId': value.lawyerId,
    };
}


