/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TRoomAccess,
    TRoomAccessFromJSON,
    TRoomAccessToJSON,
} from '../models';

export interface GetTokenRequest {
    id: string;
}

/**
 * 
 */
export class VideochatServiceApi extends runtime.BaseAPI {

    /**
     */
    async getTokenRaw(requestParameters: GetTokenRequest): Promise<runtime.ApiResponse<TRoomAccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/videochat/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TRoomAccessFromJSON(jsonValue));
    }

    /**
     */
    async getToken(requestParameters: GetTokenRequest): Promise<TRoomAccess> {
        const response = await this.getTokenRaw(requestParameters);
        return await response.value();
    }

}
