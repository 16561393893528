/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    isContract: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    bulkId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    fileName: string;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isContract': json['isContract'],
        'bulkId': json['bulkId'],
        'status': json['status'],
        'id': json['id'],
        'fileName': json['fileName'],
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isContract': value.isContract,
        'bulkId': value.bulkId,
        'status': value.status,
        'id': value.id,
        'fileName': value.fileName,
    };
}


