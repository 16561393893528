/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCreateBooking
 */
export interface TCreateBooking {
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    packageName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCreateBooking
     */
    isContract?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof TCreateBooking
     */
    free?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    lawyer?: string;
    /**
     * 
     * @type {number}
     * @memberof TCreateBooking
     */
    length: number;
    /**
     * 
     * @type {Date}
     * @memberof TCreateBooking
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    caseId?: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateBooking
     */
    about?: string;
}

export function TCreateBookingFromJSON(json: any): TCreateBooking {
    return TCreateBookingFromJSONTyped(json, false);
}

export function TCreateBookingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateBooking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packageName': !exists(json, 'package_name') ? undefined : json['package_name'],
        'isContract': !exists(json, 'isContract') ? undefined : json['isContract'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'type': json['type'],
        'free': !exists(json, 'free') ? undefined : json['free'],
        'lawyer': !exists(json, 'lawyer') ? undefined : json['lawyer'],
        'length': json['length'],
        'time': (new Date(json['time'])),
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'about': !exists(json, 'about') ? undefined : json['about'],
    };
}

export function TCreateBookingToJSON(value?: TCreateBooking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'package_name': value.packageName,
        'isContract': value.isContract,
        'user': value.user,
        'type': value.type,
        'free': value.free,
        'lawyer': value.lawyer,
        'length': value.length,
        'time': (value.time.toISOString()),
        'caseId': value.caseId,
        'about': value.about,
    };
}


