/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Booking,
    BookingFromJSON,
    BookingToJSON,
    Case,
    CaseFromJSON,
    CaseToJSON,
    PaginateResultCaseUserUserBooking,
    PaginateResultCaseUserUserBookingFromJSON,
    PaginateResultCaseUserUserBookingToJSON,
    TChangeCaseStatus,
    TChangeCaseStatusFromJSON,
    TChangeCaseStatusToJSON,
    TChangeLawyer,
    TChangeLawyerFromJSON,
    TChangeLawyerToJSON,
    TCreateCase,
    TCreateCaseFromJSON,
    TCreateCaseToJSON,
    TCreateOfferInCase,
    TCreateOfferInCaseFromJSON,
    TCreateOfferInCaseToJSON,
} from '../models';

export interface ChangeLawyerInCaseRequest {
    tChangeLawyer: TChangeLawyer;
}

export interface ChangeStatusRequest {
    tChangeCaseStatus: TChangeCaseStatus;
}

export interface CreateCaseRequest {
    tCreateCase: TCreateCase;
}

export interface CreateOfferRequest {
    tCreateOfferInCase: TCreateOfferInCase;
}

export interface GetAdminCasesRequest {
    fields?: string;
    filter?: string;
    page?: number;
    limit?: number;
    filters?: string;
}

export interface GetAllCasesRequest {
    page: number;
    limit: number;
}

export interface GetBookingsInCaseRequest {
    caseId: string;
}

export interface GetCaseRequest {
    caseId: string;
}

/**
 * 
 */
export class CaseServiceApi extends runtime.BaseAPI {

    /**
     */
    async changeLawyerInCaseRaw(requestParameters: ChangeLawyerInCaseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tChangeLawyer === null || requestParameters.tChangeLawyer === undefined) {
            throw new runtime.RequiredError('tChangeLawyer','Required parameter requestParameters.tChangeLawyer was null or undefined when calling changeLawyerInCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/case/lawyer`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TChangeLawyerToJSON(requestParameters.tChangeLawyer),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeLawyerInCase(requestParameters: ChangeLawyerInCaseRequest): Promise<void> {
        await this.changeLawyerInCaseRaw(requestParameters);
    }

    /**
     */
    async changeStatusRaw(requestParameters: ChangeStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tChangeCaseStatus === null || requestParameters.tChangeCaseStatus === undefined) {
            throw new runtime.RequiredError('tChangeCaseStatus','Required parameter requestParameters.tChangeCaseStatus was null or undefined when calling changeStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/case/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TChangeCaseStatusToJSON(requestParameters.tChangeCaseStatus),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeStatus(requestParameters: ChangeStatusRequest): Promise<void> {
        await this.changeStatusRaw(requestParameters);
    }

    /**
     */
    async createCaseRaw(requestParameters: CreateCaseRequest): Promise<runtime.ApiResponse<Case>> {
        if (requestParameters.tCreateCase === null || requestParameters.tCreateCase === undefined) {
            throw new runtime.RequiredError('tCreateCase','Required parameter requestParameters.tCreateCase was null or undefined when calling createCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/case`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateCaseToJSON(requestParameters.tCreateCase),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseFromJSON(jsonValue));
    }

    /**
     */
    async createCase(requestParameters: CreateCaseRequest): Promise<Case> {
        const response = await this.createCaseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createOfferRaw(requestParameters: CreateOfferRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tCreateOfferInCase === null || requestParameters.tCreateOfferInCase === undefined) {
            throw new runtime.RequiredError('tCreateOfferInCase','Required parameter requestParameters.tCreateOfferInCase was null or undefined when calling createOffer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/case/offer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateOfferInCaseToJSON(requestParameters.tCreateOfferInCase),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOffer(requestParameters: CreateOfferRequest): Promise<void> {
        await this.createOfferRaw(requestParameters);
    }

    /**
     */
    async getAdminCasesRaw(requestParameters: GetAdminCasesRequest): Promise<runtime.ApiResponse<PaginateResultCaseUserUserBooking>> {
        const queryParameters: any = {};

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/case/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginateResultCaseUserUserBookingFromJSON(jsonValue));
    }

    /**
     */
    async getAdminCases(requestParameters: GetAdminCasesRequest): Promise<PaginateResultCaseUserUserBooking> {
        const response = await this.getAdminCasesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCasesRaw(requestParameters: GetAllCasesRequest): Promise<runtime.ApiResponse<PaginateResultCaseUserUserBooking>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAllCases.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllCases.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/case/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginateResultCaseUserUserBookingFromJSON(jsonValue));
    }

    /**
     */
    async getAllCases(requestParameters: GetAllCasesRequest): Promise<PaginateResultCaseUserUserBooking> {
        const response = await this.getAllCasesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getBookingsInCaseRaw(requestParameters: GetBookingsInCaseRequest): Promise<runtime.ApiResponse<Array<Booking>>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getBookingsInCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/case/orders/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookingFromJSON));
    }

    /**
     */
    async getBookingsInCase(requestParameters: GetBookingsInCaseRequest): Promise<Array<Booking>> {
        const response = await this.getBookingsInCaseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCaseRaw(requestParameters: GetCaseRequest): Promise<runtime.ApiResponse<Case>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/case/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseFromJSON(jsonValue));
    }

    /**
     */
    async getCase(requestParameters: GetCaseRequest): Promise<Case> {
        const response = await this.getCaseRaw(requestParameters);
        return await response.value();
    }

}
