export function download(url, filename) {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
}

export function handleFileResponse(response) {
  return response.blob().then((blob) => {
    if (!response.ok) {
      if ([401, 403, 500].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        const url = window.location.pathname;
        // authenticationService.logout(url);
      }

      return Promise.reject("COULD_NOT_DOWNLOAD");
    }
    const fileName = decodeURI(response.headers.get("content-disposition"));
    const file = window.URL.createObjectURL(blob);
    download(file, fileName);

    return;
  });
}

export default handleFileResponse;
