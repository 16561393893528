/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {any}
     * @memberof User
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    association?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    discountId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    freeQualified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    uid: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    pepFam?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    areas?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    bankid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    urlSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    stripeCustomer?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastLogin?: Date;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'name': json['name'],
        'email': json['email'],
        'association': !exists(json, 'association') ? undefined : json['association'],
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'role': json['role'],
        'freeQualified': !exists(json, 'freeQualified') ? undefined : json['freeQualified'],
        'uid': json['uid'],
        'pep': !exists(json, 'pep') ? undefined : json['pep'],
        'pepFam': !exists(json, 'pepFam') ? undefined : json['pepFam'],
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'bankid': !exists(json, 'bankid') ? undefined : json['bankid'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'urlSlug': !exists(json, 'urlSlug') ? undefined : json['urlSlug'],
        'stripeCustomer': !exists(json, 'stripe_customer') ? undefined : json['stripe_customer'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'lastLogin': !exists(json, 'lastLogin') ? undefined : (new Date(json['lastLogin'])),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'name': value.name,
        'email': value.email,
        'association': value.association,
        'discountId': value.discountId,
        'role': value.role,
        'freeQualified': value.freeQualified,
        'uid': value.uid,
        'pep': value.pep,
        'pepFam': value.pepFam,
        'areas': value.areas,
        'bankid': value.bankid,
        'active': value.active,
        'urlSlug': value.urlSlug,
        'stripe_customer': value.stripeCustomer,
        'phone': value.phone,
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin.toISOString()),
    };
}


