/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Wizard,
    WizardFromJSON,
    WizardFromJSONTyped,
    WizardToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginateResultWizard
 */
export interface PaginateResultWizard {
    /**
     * 
     * @type {any}
     * @memberof PaginateResultWizard
     */
    meta?: any | null;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    pagingCounter: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    nextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    prevPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    page?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginateResultWizard
     */
    hasNextPage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginateResultWizard
     */
    hasPrevPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateResultWizard
     */
    totalDocs: number;
    /**
     * 
     * @type {Array<Wizard>}
     * @memberof PaginateResultWizard
     */
    docs: Array<Wizard>;
}

export function PaginateResultWizardFromJSON(json: any): PaginateResultWizard {
    return PaginateResultWizardFromJSONTyped(json, false);
}

export function PaginateResultWizardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginateResultWizard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
        'pagingCounter': json['pagingCounter'],
        'nextPage': !exists(json, 'nextPage') ? undefined : json['nextPage'],
        'prevPage': !exists(json, 'prevPage') ? undefined : json['prevPage'],
        'offset': json['offset'],
        'totalPages': json['totalPages'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'hasNextPage': json['hasNextPage'],
        'hasPrevPage': json['hasPrevPage'],
        'limit': json['limit'],
        'totalDocs': json['totalDocs'],
        'docs': ((json['docs'] as Array<any>).map(WizardFromJSON)),
    };
}

export function PaginateResultWizardToJSON(value?: PaginateResultWizard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': value.meta,
        'pagingCounter': value.pagingCounter,
        'nextPage': value.nextPage,
        'prevPage': value.prevPage,
        'offset': value.offset,
        'totalPages': value.totalPages,
        'page': value.page,
        'hasNextPage': value.hasNextPage,
        'hasPrevPage': value.hasPrevPage,
        'limit': value.limit,
        'totalDocs': value.totalDocs,
        'docs': ((value.docs as Array<any>).map(WizardToJSON)),
    };
}


