/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Make all properties in T optional
 * @export
 * @interface PartialBooking
 */
export interface PartialBooking {
    /**
     * 
     * @type {any}
     * @memberof PartialBooking
     */
    id?: any | null;
    /**
     * 
     * @type {Date}
     * @memberof PartialBooking
     */
    time?: Date;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    lawyer?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    lastAlert?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    _case?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    about?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBooking
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    clientSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBooking
     */
    payed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBooking
     */
    free?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    payment?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    paymentIntent?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBooking
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    calendarId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBooking
     */
    discount?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBooking
     */
    isContract?: boolean;
}

export function PartialBookingFromJSON(json: any): PartialBooking {
    return PartialBookingFromJSONTyped(json, false);
}

export function PartialBookingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialBooking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'lawyer': !exists(json, 'lawyer') ? undefined : json['lawyer'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'lastAlert': !exists(json, 'lastAlert') ? undefined : json['lastAlert'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        '_case': !exists(json, 'case') ? undefined : json['case'],
        'about': !exists(json, 'about') ? undefined : json['about'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'payed': !exists(json, 'payed') ? undefined : json['payed'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'free': !exists(json, 'free') ? undefined : json['free'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'payment': !exists(json, 'payment') ? undefined : json['payment'],
        'paymentIntent': !exists(json, 'paymentIntent') ? undefined : json['paymentIntent'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'calendarId': !exists(json, 'calendarId') ? undefined : json['calendarId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'isContract': !exists(json, 'isContract') ? undefined : json['isContract'],
    };
}

export function PartialBookingToJSON(value?: PartialBooking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'lawyer': value.lawyer,
        'password': value.password,
        'lastAlert': value.lastAlert,
        'user': value.user,
        'case': value._case,
        'about': value.about,
        'length': value.length,
        'clientSecret': value.clientSecret,
        'payed': value.payed,
        'phone': value.phone,
        'free': value.free,
        'type': value.type,
        'createdBy': value.createdBy,
        'payment': value.payment,
        'paymentIntent': value.paymentIntent,
        'price': value.price,
        'calendarId': value.calendarId,
        'status': value.status,
        'discount': value.discount,
        'isContract': value.isContract,
    };
}


