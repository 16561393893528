/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Booking,
    BookingFromJSON,
    BookingFromJSONTyped,
    BookingToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CaseUserUserBooking
 */
export interface CaseUserUserBooking {
    /**
     * 
     * @type {any}
     * @memberof CaseUserUserBooking
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUserUserBooking
     */
    title: string;
    /**
     * 
     * @type {User}
     * @memberof CaseUserUserBooking
     */
    user: User;
    /**
     * 
     * @type {Array<Booking>}
     * @memberof CaseUserUserBooking
     */
    orders: Array<Booking>;
    /**
     * 
     * @type {string}
     * @memberof CaseUserUserBooking
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof CaseUserUserBooking
     */
    stage?: number;
    /**
     * 
     * @type {User}
     * @memberof CaseUserUserBooking
     */
    lawyer?: User;
    /**
     * 
     * @type {number}
     * @memberof CaseUserUserBooking
     */
    uid?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CaseUserUserBooking
     */
    isContract?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CaseUserUserBooking
     */
    referingBank?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUserUserBooking
     */
    contractType?: string;
    /**
     * 
     * @type {any}
     * @memberof CaseUserUserBooking
     */
    wizardData?: any | null;
    /**
     * 
     * @type {Date}
     * @memberof CaseUserUserBooking
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CaseUserUserBooking
     */
    updatedAt: Date;
    /**
     * 
     * @type {any}
     * @memberof CaseUserUserBooking
     */
    contractData?: any | null;
}

export function CaseUserUserBookingFromJSON(json: any): CaseUserUserBooking {
    return CaseUserUserBookingFromJSONTyped(json, false);
}

export function CaseUserUserBookingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseUserUserBooking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'title': json['title'],
        'user': UserFromJSON(json['user']),
        'orders': ((json['orders'] as Array<any>).map(BookingFromJSON)),
        'status': json['status'],
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'lawyer': !exists(json, 'lawyer') ? undefined : UserFromJSON(json['lawyer']),
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'isContract': !exists(json, 'isContract') ? undefined : json['isContract'],
        'referingBank': !exists(json, 'referingBank') ? undefined : json['referingBank'],
        'contractType': !exists(json, 'contractType') ? undefined : json['contractType'],
        'wizardData': !exists(json, 'wizardData') ? undefined : json['wizardData'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'contractData': !exists(json, 'contractData') ? undefined : json['contractData'],
    };
}

export function CaseUserUserBookingToJSON(value?: CaseUserUserBooking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'title': value.title,
        'user': UserToJSON(value.user),
        'orders': ((value.orders as Array<any>).map(BookingToJSON)),
        'status': value.status,
        'stage': value.stage,
        'lawyer': UserToJSON(value.lawyer),
        'uid': value.uid,
        'isContract': value.isContract,
        'referingBank': value.referingBank,
        'contractType': value.contractType,
        'wizardData': value.wizardData,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'contractData': value.contractData,
    };
}


