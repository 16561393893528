/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
} from '../models';

export interface DeleteFileRequest {
    body: any | null;
}

export interface DownloadFileRequest {
    fileId: string;
}

export interface GetAllFilesRequest {
    caseId: string;
}

export interface UploadFilesRequest {
    caseId: string;
    uploadType: string;
    files: Array<Blob>;
}

/**
 * 
 */
export class FileServiceApi extends runtime.BaseAPI {

    /**
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling deleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/file/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFile(requestParameters: DeleteFileRequest): Promise<void> {
        await this.deleteFileRaw(requestParameters);
    }

    /**
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/download/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async downloadFile(requestParameters: DownloadFileRequest): Promise<any> {
        const response = await this.downloadFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllFilesRaw(requestParameters: GetAllFilesRequest): Promise<runtime.ApiResponse<Array<InlineResponse200>>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getAllFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/all/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InlineResponse200FromJSON));
    }

    /**
     */
    async getAllFiles(requestParameters: GetAllFilesRequest): Promise<Array<InlineResponse200>> {
        const response = await this.getAllFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadFilesRaw(requestParameters: UploadFilesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling uploadFiles.');
        }

        if (requestParameters.uploadType === null || requestParameters.uploadType === undefined) {
            throw new runtime.RequiredError('uploadType','Required parameter requestParameters.uploadType was null or undefined when calling uploadFiles.');
        }

        if (requestParameters.files === null || requestParameters.files === undefined) {
            throw new runtime.RequiredError('files','Required parameter requestParameters.files was null or undefined when calling uploadFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.caseId !== undefined) {
            formParams.append('caseId', requestParameters.caseId as any);
        }

        if (requestParameters.uploadType !== undefined) {
            formParams.append('uploadType', requestParameters.uploadType as any);
        }

        if (requestParameters.files) {
            formParams.append('files', requestParameters.files.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/file/multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadFiles(requestParameters: UploadFilesRequest): Promise<void> {
        await this.uploadFilesRaw(requestParameters);
    }

}
