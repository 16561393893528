/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TChangeCaseStatus
 */
export interface TChangeCaseStatus {
    /**
     * 
     * @type {string}
     * @memberof TChangeCaseStatus
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof TChangeCaseStatus
     */
    caseId: string;
}

export function TChangeCaseStatusFromJSON(json: any): TChangeCaseStatus {
    return TChangeCaseStatusFromJSONTyped(json, false);
}

export function TChangeCaseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TChangeCaseStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'caseId': json['caseId'],
    };
}

export function TChangeCaseStatusToJSON(value?: TChangeCaseStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'caseId': value.caseId,
    };
}


