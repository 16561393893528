/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetAllMessagesRequest {
    id: string;
}

/**
 * 
 */
export class MessageServiceApi extends runtime.BaseAPI {

    /**
     */
    async getAllMessagesRaw(requestParameters: GetAllMessagesRequest): Promise<runtime.ApiResponse<Array<any>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/all/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAllMessages(requestParameters: GetAllMessagesRequest): Promise<Array<any>> {
        const response = await this.getAllMessagesRaw(requestParameters);
        return await response.value();
    }

}
