/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Booking,
    BookingFromJSON,
    BookingFromJSONTyped,
    BookingToJSON,
} from './';

/**
 * 
 * @export
 * @interface TRoomAccess
 */
export interface TRoomAccess {
    /**
     * 
     * @type {Booking}
     * @memberof TRoomAccess
     */
    order: Booking;
    /**
     * 
     * @type {string}
     * @memberof TRoomAccess
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof TRoomAccess
     */
    token: string;
}

export function TRoomAccessFromJSON(json: any): TRoomAccess {
    return TRoomAccessFromJSONTyped(json, false);
}

export function TRoomAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TRoomAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': BookingFromJSON(json['order']),
        'password': json['password'],
        'token': json['token'],
    };
}

export function TRoomAccessToJSON(value?: TRoomAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': BookingToJSON(value.order),
        'password': value.password,
        'token': value.token,
    };
}


