/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {any}
     * @memberof Payment
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    _case: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    time: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    referingBank?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    payed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    packageName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    isPackage?: boolean;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'user': json['user'],
        '_case': json['case'],
        'price': json['price'],
        'description': json['description'],
        'time': json['time'],
        'referingBank': !exists(json, 'referingBank') ? undefined : json['referingBank'],
        'payed': json['payed'],
        'clientSecret': json['clientSecret'],
        'packageName': !exists(json, 'packageName') ? undefined : json['packageName'],
        'isPackage': !exists(json, 'isPackage') ? undefined : json['isPackage'],
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'user': value.user,
        'case': value._case,
        'price': value.price,
        'description': value.description,
        'time': value.time,
        'referingBank': value.referingBank,
        'payed': value.payed,
        'clientSecret': value.clientSecret,
        'packageName': value.packageName,
        'isPackage': value.isPackage,
    };
}


