import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import RoundedButton from "../button/RoundedButton";
import { Button } from "@chakra-ui/button";
import { ButtonGroup } from "@chakra-ui/button";

export default function CookieAlert({}) {
  const [display, setDisplay] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const accepted = localStorage.getItem("cookies_consent");
    if (!accepted) {
      if (accepted !== "true") {
        setDisplay(true);
      }
    }
  }, []);

  function accept() {
    localStorage.setItem("cookies_consent", "true");
    setDisplay(false);
  }

  return (
    <div className="root">
      <span className="title">Vi bruker cookies for å:</span>
      <ol>
        <li>Sørge for at betalinger som skjer ikke er svindelforsøk</li>
        <li>Se anonymisert statistikk om brukerne våre</li>
        <li>Identifisere deg når du bruker tjenesten</li>
      </ol>
      <div className="actions">
        <div>
          <Button
            onClick={() => accept()}
            variant="solid"
            aria-label="Aksepter cookies på siden"
          >
            Aksepter
          </Button>
        </div>
        <div>
          <Button
            background="var(--high-grey)"
            aria-label="Les mer om personvern"
            onClick={() => router.push("/personvern")}
          >
            Mer info
          </Button>
        </div>
      </div>
      <style jsx>{`
        .root {
          max-width: 400px;
          position: fixed;
          bottom: 20px;
          z-index: 99;
          display: ${display ? "block" : "none"};
          right: 20px;
          margin-left: 20px;
          border-radius: 20px;
          background: white;
          border: 3px solid var(--main);
          padding: 20px;
        }
        .root li {
          margin-left: 20px;
        }
        .root ol {
          margin-left: 0;
          margin-bottom: 15px;
        }
        .actions {
          display: grid;
          grid-template-columns: min-content min-content;
          grid-gap: 0 10px;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
        }
      `}</style>
    </div>
  );
}
