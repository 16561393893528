/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginateResultWizard,
    PaginateResultWizardFromJSON,
    PaginateResultWizardToJSON,
    TCreateWizard,
    TCreateWizardFromJSON,
    TCreateWizardToJSON,
    Wizard,
    WizardFromJSON,
    WizardToJSON,
} from '../models';

export interface CreateWizardRequest {
    tCreateWizard: TCreateWizard;
}

export interface DeleteWizardRequest {
    slug: string;
}

export interface GetWizardRequest {
    slug: string;
}

/**
 * 
 */
export class WizardServiceApi extends runtime.BaseAPI {

    /**
     */
    async createWizardRaw(requestParameters: CreateWizardRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.tCreateWizard === null || requestParameters.tCreateWizard === undefined) {
            throw new runtime.RequiredError('tCreateWizard','Required parameter requestParameters.tCreateWizard was null or undefined when calling createWizard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/wizards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateWizardToJSON(requestParameters.tCreateWizard),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createWizard(requestParameters: CreateWizardRequest): Promise<any> {
        const response = await this.createWizardRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteWizardRaw(requestParameters: DeleteWizardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling deleteWizard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wizards/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteWizard(requestParameters: DeleteWizardRequest): Promise<void> {
        await this.deleteWizardRaw(requestParameters);
    }

    /**
     */
    async getAllWizardsRaw(): Promise<runtime.ApiResponse<PaginateResultWizard>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wizards/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginateResultWizardFromJSON(jsonValue));
    }

    /**
     */
    async getAllWizards(): Promise<PaginateResultWizard> {
        const response = await this.getAllWizardsRaw();
        return await response.value();
    }

    /**
     */
    async getWizardRaw(requestParameters: GetWizardRequest): Promise<runtime.ApiResponse<Wizard>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getWizard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wizards/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardFromJSON(jsonValue));
    }

    /**
     */
    async getWizard(requestParameters: GetWizardRequest): Promise<Wizard> {
        const response = await this.getWizardRaw(requestParameters);
        return await response.value();
    }

}
