import { authUrl, frontendUri, redirectUri } from "./config";
import { AuthServiceApi, Configuration } from "./gen";
import config from "../config/api-config";

export const auth = {
  storeUser,
  logout,
  login,
};

function login(referal: string = null) {
  const state = JSON.stringify({
    path: window.location.href,
  });
  if (referal) {
    window.location.href = `${authUrl}/dialog/authorize?client_id=timebooking&response_type=code&redirect_uri=${redirectUri}&referal=${referal}&state=${encodeURIComponent(
      state
    )}`;
  } else {
    window.location.href = `${authUrl}/dialog/authorize?client_id=timebooking&response_type=code&redirect_uri=${redirectUri}&state=${encodeURIComponent(
      state
    )}`;
  }
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.href = authUrl + `/logout?return_to=${frontendUri}`;
}

function storeUser(token) {
  return new Promise((resolve, reject) => {
    localStorage.setItem("token", token);
    setTimeout(() => {
      new AuthServiceApi(new Configuration(config))
        .getUser()
        .then((user) => {
          localStorage.setItem("user", JSON.stringify(user));
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    }, 1000);
  });
}

export default auth;
