/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {any}
     * @memberof Case
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    user: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Case
     */
    orders: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    stage?: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    lawyer?: string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    uid?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    isContract?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    referingBank?: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    contractType?: string;
    /**
     * 
     * @type {any}
     * @memberof Case
     */
    wizardData?: any | null;
    /**
     * 
     * @type {Date}
     * @memberof Case
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Case
     */
    updatedAt: Date;
    /**
     * 
     * @type {any}
     * @memberof Case
     */
    contractData?: any | null;
}

export function CaseFromJSON(json: any): Case {
    return CaseFromJSONTyped(json, false);
}

export function CaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Case {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'title': json['title'],
        'user': json['user'],
        'orders': json['orders'],
        'status': json['status'],
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'lawyer': !exists(json, 'lawyer') ? undefined : json['lawyer'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'isContract': !exists(json, 'isContract') ? undefined : json['isContract'],
        'referingBank': !exists(json, 'referingBank') ? undefined : json['referingBank'],
        'contractType': !exists(json, 'contractType') ? undefined : json['contractType'],
        'wizardData': !exists(json, 'wizardData') ? undefined : json['wizardData'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'contractData': !exists(json, 'contractData') ? undefined : json['contractData'],
    };
}

export function CaseToJSON(value?: Case | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'title': value.title,
        'user': value.user,
        'orders': value.orders,
        'status': value.status,
        'stage': value.stage,
        'lawyer': value.lawyer,
        'uid': value.uid,
        'isContract': value.isContract,
        'referingBank': value.referingBank,
        'contractType': value.contractType,
        'wizardData': value.wizardData,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'contractData': value.contractData,
    };
}


