/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TCreateOfferInCase
 */
export interface TCreateOfferInCase {
    /**
     * 
     * @type {string}
     * @memberof TCreateOfferInCase
     */
    caseId: string;
    /**
     * 
     * @type {string}
     * @memberof TCreateOfferInCase
     */
    packageName: string;
}

export function TCreateOfferInCaseFromJSON(json: any): TCreateOfferInCase {
    return TCreateOfferInCaseFromJSONTyped(json, false);
}

export function TCreateOfferInCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateOfferInCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseId': json['caseId'],
        'packageName': json['package_name'],
    };
}

export function TCreateOfferInCaseToJSON(value?: TCreateOfferInCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseId': value.caseId,
        'package_name': value.packageName,
    };
}


