/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ESparebank1Contract,
    ESparebank1ContractFromJSON,
    ESparebank1ContractFromJSONTyped,
    ESparebank1ContractToJSON,
} from './';

/**
 * 
 * @export
 * @interface TCreateSparebank1Intent
 */
export interface TCreateSparebank1Intent {
    /**
     * 
     * @type {any}
     * @memberof TCreateSparebank1Intent
     */
    contractData?: any | null;
    /**
     * 
     * @type {number}
     * @memberof TCreateSparebank1Intent
     */
    referingBank: number;
    /**
     * 
     * @type {string}
     * @memberof TCreateSparebank1Intent
     */
    file?: string;
    /**
     * 
     * @type {ESparebank1Contract}
     * @memberof TCreateSparebank1Intent
     */
    contractType: ESparebank1Contract;
    /**
     * 
     * @type {string}
     * @memberof TCreateSparebank1Intent
     */
    ssn: string;
}

export function TCreateSparebank1IntentFromJSON(json: any): TCreateSparebank1Intent {
    return TCreateSparebank1IntentFromJSONTyped(json, false);
}

export function TCreateSparebank1IntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TCreateSparebank1Intent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractData': !exists(json, 'contractData') ? undefined : json['contractData'],
        'referingBank': json['referingBank'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'contractType': ESparebank1ContractFromJSON(json['contractType']),
        'ssn': json['ssn'],
    };
}

export function TCreateSparebank1IntentToJSON(value?: TCreateSparebank1Intent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractData': value.contractData,
        'referingBank': value.referingBank,
        'file': value.file,
        'contractType': ESparebank1ContractToJSON(value.contractType),
        'ssn': value.ssn,
    };
}


