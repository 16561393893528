/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Booking,
    BookingFromJSON,
    BookingToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    PaginateResultBooking,
    PaginateResultBookingFromJSON,
    PaginateResultBookingToJSON,
    PartialBooking,
    PartialBookingFromJSON,
    PartialBookingToJSON,
    TCreateBooking,
    TCreateBookingFromJSON,
    TCreateBookingToJSON,
    TCreateSparebank1Intent,
    TCreateSparebank1IntentFromJSON,
    TCreateSparebank1IntentToJSON,
    TCreatedIntentSparebank1,
    TCreatedIntentSparebank1FromJSON,
    TCreatedIntentSparebank1ToJSON,
} from '../models';

export interface CancelBookingRequest {
    inlineObject: InlineObject;
}

export interface CreateBookingRequest {
    tCreateBooking: TCreateBooking;
}

export interface CreateIntentRequest {
    tCreateSparebank1Intent: TCreateSparebank1Intent;
}

export interface GetAllBookingsRequest {
    page: number;
    limit: number;
}

export interface GetBookingRequest {
    bookingId: string;
}

export interface SetPhoneRequest {
    inlineObject1: InlineObject1;
}

export interface UpdateBookingRequest {
    partialBooking: PartialBooking;
}

/**
 * 
 */
export class OrderServiceApi extends runtime.BaseAPI {

    /**
     */
    async cancelBookingRaw(requestParameters: CancelBookingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError('inlineObject','Required parameter requestParameters.inlineObject was null or undefined when calling cancelBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/cancel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelBooking(requestParameters: CancelBookingRequest): Promise<void> {
        await this.cancelBookingRaw(requestParameters);
    }

    /**
     */
    async createBookingRaw(requestParameters: CreateBookingRequest): Promise<runtime.ApiResponse<Booking>> {
        if (requestParameters.tCreateBooking === null || requestParameters.tCreateBooking === undefined) {
            throw new runtime.RequiredError('tCreateBooking','Required parameter requestParameters.tCreateBooking was null or undefined when calling createBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateBookingToJSON(requestParameters.tCreateBooking),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue));
    }

    /**
     */
    async createBooking(requestParameters: CreateBookingRequest): Promise<Booking> {
        const response = await this.createBookingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createIntentRaw(requestParameters: CreateIntentRequest): Promise<runtime.ApiResponse<TCreatedIntentSparebank1>> {
        if (requestParameters.tCreateSparebank1Intent === null || requestParameters.tCreateSparebank1Intent === undefined) {
            throw new runtime.RequiredError('tCreateSparebank1Intent','Required parameter requestParameters.tCreateSparebank1Intent was null or undefined when calling createIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/book`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateSparebank1IntentToJSON(requestParameters.tCreateSparebank1Intent),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TCreatedIntentSparebank1FromJSON(jsonValue));
    }

    /**
     */
    async createIntent(requestParameters: CreateIntentRequest): Promise<TCreatedIntentSparebank1> {
        const response = await this.createIntentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllBookingsRaw(requestParameters: GetAllBookingsRequest): Promise<runtime.ApiResponse<PaginateResultBooking>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAllBookings.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllBookings.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginateResultBookingFromJSON(jsonValue));
    }

    /**
     */
    async getAllBookings(requestParameters: GetAllBookingsRequest): Promise<PaginateResultBooking> {
        const response = await this.getAllBookingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the booking for the supplied id.
     */
    async getBookingRaw(requestParameters: GetBookingRequest): Promise<runtime.ApiResponse<Booking>> {
        if (requestParameters.bookingId === null || requestParameters.bookingId === undefined) {
            throw new runtime.RequiredError('bookingId','Required parameter requestParameters.bookingId was null or undefined when calling getBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{bookingId}`.replace(`{${"bookingId"}}`, encodeURIComponent(String(requestParameters.bookingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue));
    }

    /**
     * Returns the booking for the supplied id.
     */
    async getBooking(requestParameters: GetBookingRequest): Promise<Booking> {
        const response = await this.getBookingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async setPhoneRaw(requestParameters: SetPhoneRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inlineObject1 === null || requestParameters.inlineObject1 === undefined) {
            throw new runtime.RequiredError('inlineObject1','Required parameter requestParameters.inlineObject1 was null or undefined when calling setPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/phone`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.inlineObject1),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setPhone(requestParameters: SetPhoneRequest): Promise<void> {
        await this.setPhoneRaw(requestParameters);
    }

    /**
     */
    async updateBookingRaw(requestParameters: UpdateBookingRequest): Promise<runtime.ApiResponse<Booking>> {
        if (requestParameters.partialBooking === null || requestParameters.partialBooking === undefined) {
            throw new runtime.RequiredError('partialBooking','Required parameter requestParameters.partialBooking was null or undefined when calling updateBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartialBookingToJSON(requestParameters.partialBooking),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue));
    }

    /**
     */
    async updateBooking(requestParameters: UpdateBookingRequest): Promise<Booking> {
        const response = await this.updateBookingRaw(requestParameters);
        return await response.value();
    }

}
