/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Payment,
    PaymentFromJSON,
    PaymentToJSON,
    TCreateOffer,
    TCreateOfferFromJSON,
    TCreateOfferToJSON,
} from '../models';

export interface CreatePaymentRequest {
    tCreateOffer: TCreateOffer;
}

export interface GetPaymentRequest {
    id: string;
}

/**
 * 
 */
export class PaymentServiceApi extends runtime.BaseAPI {

    /**
     */
    async createPaymentRaw(requestParameters: CreatePaymentRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.tCreateOffer === null || requestParameters.tCreateOffer === undefined) {
            throw new runtime.RequiredError('tCreateOffer','Required parameter requestParameters.tCreateOffer was null or undefined when calling createPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TCreateOfferToJSON(requestParameters.tCreateOffer),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     */
    async createPayment(requestParameters: CreatePaymentRequest): Promise<Payment> {
        const response = await this.createPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPaymentRaw(requestParameters: GetPaymentRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     */
    async getPayment(requestParameters: GetPaymentRequest): Promise<Payment> {
        const response = await this.getPaymentRaw(requestParameters);
        return await response.value();
    }

}
