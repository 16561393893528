/* tslint:disable */
/* eslint-disable */
/**
 * timebooking
 * Timebooking
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountToJSON,
    TSetDiscount,
    TSetDiscountFromJSON,
    TSetDiscountToJSON,
} from '../models';

export interface GetDiscountRequest {
    slug: string;
}

export interface SetDiscountForUserRequest {
    tSetDiscount: TSetDiscount;
}

/**
 * 
 */
export class DiscountServiceApi extends runtime.BaseAPI {

    /**
     */
    async getDiscountRaw(requestParameters: GetDiscountRequest): Promise<runtime.ApiResponse<Discount>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discount/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountFromJSON(jsonValue));
    }

    /**
     */
    async getDiscount(requestParameters: GetDiscountRequest): Promise<Discount> {
        const response = await this.getDiscountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async setDiscountForUserRaw(requestParameters: SetDiscountForUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tSetDiscount === null || requestParameters.tSetDiscount === undefined) {
            throw new runtime.RequiredError('tSetDiscount','Required parameter requestParameters.tSetDiscount was null or undefined when calling setDiscountForUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TSetDiscountToJSON(requestParameters.tSetDiscount),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setDiscountForUser(requestParameters: SetDiscountForUserRequest): Promise<void> {
        await this.setDiscountForUserRaw(requestParameters);
    }

}
