export const GA_TRACKING_ID = "G-VJ699CX255";

interface GTagWindow extends Window {
  gtag?: any;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  (window as GTagWindow).gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, data }) => {
  (window as GTagWindow).gtag("event", action, data);
};
