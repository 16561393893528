import React, { useContext, useEffect } from "react";
import { AlertContext } from "src/components/context/AlertContext";

export default function Alert({ alert }) {
  const { alertContext, setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (alert && alert.timeout) {
      setTimeout(() => setAlert(null), alert.timeout);
    }
  }, [alert]);

  return (
    <div className="root">
      <div className="text">
        {alert && alert.title && <span className="title">{alert.title}</span>}
        <span className="message">{alert && alert.message}</span>
      </div>
      <img
        src="/images/icons/close.png"
        alt=""
        className="closeIcon"
        onClick={() => setAlert(null)}
      />
      <style jsx>{`
        .root {
          border: 3px solid
            ${alert && alert.type === "error"
              ? "var(--dangerous-red)"
              : "black"};
          padding: 10px;
          display: flex;
          position: fixed;
          background: white;
          width: 1200px;
          transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
          top: ${alert ? "100px" : "-100px"};
          left: 50%;
          z-index: 99999;
          margin-left: calc(-1200px / 2);
          border-radius: 50px;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          flex-grow: 1;
        }
        .title {
          font-weight: 800;
          margin-bottom: 10px;
        }
        .closeIcon {
          height: 30px;
          width: 30px;
          align-self: center;
          margin: 0 20px 0 20px;
        }
        .closeIcon:hover {
          filter: invert(50%);
        }
        @media only screen and (max-width: 1200px) {
          .root {
            width: 95%;
            left: 10px;
            margin-left: 0%;
          }
          .text {
            padding: 0 20px 0 20px;
          }
        }
      `}</style>
    </div>
  );
}
